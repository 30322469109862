// @ts-nocheck

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";

import { MdFolderZip, MdContentCut, MdFingerprint } from "react-icons/md";

const Home = () => {
  const auth = useAuth();

  return (
    <main className="pt-28 pb-24 w-full min-h-screen flex flex-col bg-[#322036]">
      <div className="w-full flex flex-col justify-center items-center flex-1">
        <div className="w-full flex flex-col items-center">
          <img
            data-aos="zoom-in"
            data-aos-easing="ease"
            data-aos-duration="900"
            alt=""
            src="/assets/images/Logo.png"
            className="w-auto h-20 md:h-28"
          />
          <h1 className="mt-2 md:mt-4 text-center text-lg md:text-2xl lg:text-2xl xl:text-2xl font-roboto font-semibold leading-8 tracking-wide text-white">
            ¡Bienvenido {auth.user?.name}!
          </h1>
          <p className="mt-2 md:mt-4 text-center text-2xl md:text-4xl lg:text-4xl xl:text-4xl font-roboto font-semibold leading-8 tracking-wide text-white">
            ¿Cuál función deseas realizar?
          </p>
        </div>

        <div className="w-10/12 md:w-11/12 lg:w-10/12 xl:w-7/12 mt-14 md:mt-20 grid grid-cols-1 md:grid-cols-2 gap-14 xl:gap-24">
          <Link to="/recortes">
            <div
              data-aos="fade-up"
              data-aos-easing="ease"
              data-aos-duration="900"
            >
              <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg border border-neutral-800 bg-gradient-to-b from-neutral-900 to-neutral-100 hover:scale-105 duration-300 hover:bg-neutral-800">
                <img
                  alt=""
                  src="/assets/images/george-prentzas-SRFG7iwktDk-unsplash.jpg"
                  className="absolute inset-0 h-full w-full object-cover"
                />

                <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-10 lg:pt-36">
                  <div className="p-4 sm:p-6">
                    <MdFolderZip className="block text-2xl md:text-3xl lg:text-4xl text-white/90" />

                    <div>
                      <h3 className="mt-3 text-base md:text-lg text-white">
                        Recortes de fichas
                      </h3>
                    </div>

                    <p className="mt-2 line-clamp-3 text-xs/relaxed md:text-sm/relaxed text-white/95">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                      Recusandae dolores, possimus pariatur animi temporibus
                      nesciunt praesentium dolore sed nulla ipsum eveniet
                      corporis quidem, mollitia itaque minus soluta, voluptates
                      neque explicabo tempora nisi culpa eius atque dignissimos.
                      Molestias explicabo corporis voluptatem?
                    </p>
                  </div>
                </div>
              </article>
            </div>
          </Link>
          <div
            data-aos="fade-up"
            data-aos-easing="ease"
            data-aos-duration="900"
            data-aos-delay="100"
          >
            <article className="relative overflow-hidden rounded-lg shadow transition hover:shadow-lg border border-neutral-800 bg-gradient-to-b from-neutral-900 to-neutral-100 hover:scale-105 duration-300 hover:bg-neutral-800">
              <img
                alt=""
                src="/assets/images/immo-wegmann-5PqBCWUtYbo-unsplash.jpg"
                className="absolute inset-0 h-full w-full object-cover"
              />

              <div className="relative bg-gradient-to-t from-gray-900/50 to-gray-900/25 pt-10 lg:pt-36">
                <div className="p-4 sm:p-6">
                  <MdFingerprint className="block text-2xl md:text-3xl lg:text-4xl text-white/90" />

                  <div>
                    <h3 className="mt-3 text-base md:text-lg text-white">
                      Detección de minucias
                    </h3>
                  </div>

                  <p className="mt-2 line-clamp-3 text-xs/relaxed md:text-sm/relaxed text-white/95">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Recusandae dolores, possimus pariatur animi temporibus
                    nesciunt praesentium dolore sed nulla ipsum eveniet corporis
                    quidem, mollitia itaque minus soluta, voluptates neque
                    explicabo tempora nisi culpa eius atque dignissimos.
                    Molestias explicabo corporis voluptatem?
                  </p>
                </div>
              </div>
            </article>
          </div>
        </div>

        <div
          id="faq"
          className="mt-20 p-10 pb-20 w-full flex flex-col justify-center items-center bg-[#D4C5DB]"
        >
          <div className="w-full">
            <h2 className="text-center text-2xl font-roboto font-semibold leading-8 tracking-wide text-[#9B7ED6]">
              Preguntas frecuentes
            </h2>
          </div>
          <div className="w-10/12 md:w-11/12 lg:w-10/12 xl:w-7/12 mt-10 divide-y divide-[#9B7ED6] rounded-lg border border-[#D9D9D9] bg-[#D9D9D9]">
            <details
              className="group p-6 [&_summary::-webkit-details-marker]:hidden"
              open
            >
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
                <h2 className="text-lg font-medium text-[#493352]">
                  Lorem ipsum dolor sit amet consectetur adipisicing?
                </h2>

                <span className="relative size-5 shrink-0">
                  <svg
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic
                veritatis molestias culpa in, recusandae laboriosam neque
                aliquid libero nesciunt voluptate dicta quo officiis explicabo
                consequuntur distinctio corporis earum similique!
              </p>
            </details>

            <details className="group p-6 [&_summary::-webkit-details-marker]:hidden">
              <summary className="flex cursor-pointer items-center justify-between gap-1.5 text-gray-900">
                <h2 className="text-lg font-medium text-[#493352]">
                  Lorem ipsum dolor sit amet consectetur adipisicing?
                </h2>

                <span className="relative size-5 shrink-0">
                  <svg
                    class="size-5 shrink-0 transition duration-300 group-open:-rotate-180"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </summary>

              <p className="mt-4 leading-relaxed text-gray-700">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ab hic
                veritatis molestias culpa in, recusandae laboriosam neque
                aliquid libero nesciunt voluptate dicta quo officiis explicabo
                consequuntur distinctio corporis earum similique!
              </p>
            </details>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Home;
