import { FC } from 'react';

// Types
import { FilePickerProps } from '@/types/props';

const FilePicker: FC<FilePickerProps> = ({ onFileChange, label, loading, disabled }) => (
  <div className='form-control w-full max-w-md'>
    <label className='label'>
      <span className='label-text text-white font-semibold'>{label}</span>
      {loading && (
        <div className='flex items-center'>
          <svg
            className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
          <span className='label-text text-white font-normal'>Cargando...</span>
        </div>
      )}
    </label>
    <input
      type='file'
      className='file-input file-input-bordered w-full max-w-md disabled:border-gray-400 disabled:bg-gray-400 disabled:cursor-not-allowed'
      accept='application/pdf'
      disabled={loading || disabled}
      onChange={onFileChange}
    />
    <label className='label'>
      <span className='label-text-alt text-white'>Sólo se aceptan archivos tipo .PDF</span>
    </label>
  </div>
);

export default FilePicker;
