// @ts-nocheck

import { useContext, createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(localStorage.getItem("site") || "");
  const navigate = useNavigate();

  const loginAction = async data => {
    // try {
    //   const response = await fetch("your-api-endpoint/auth/login", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   });
    //   const res = await response.json();
    //   if (res.data) {
    //     setUser(res.data.user);
    //     setToken(res.token);
    //     localStorage.setItem("site", res.token);
    //     navigate("/");
    //     return;
    //   }
    //   throw new Error(res.message);
    // } catch (err) {
    //   console.error(err);
    // }

    // alert(Object.keys(data) + " " + Object.values(data));

    if (
      data.email === "jorge.andrade@datacivica.org" &&
      data.password === "mateRIVI"
    ) {
      setUser({
        username: "jorge",
        name: "Jorge",
        email: "jorge.andrade@datacivica.org",
      });
      setToken("8BsF}3#[C@5<sH.TfXR#");
      localStorage.setItem("site", "8BsF}3#[C@5<sH.TfXR#");
      navigate("/");
      return;
    }
    alert("Correo electrónico o contraseña incorrectos");
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    localStorage.removeItem("site");
    navigate("/login");
  };

  useEffect(() => {
    // axios.get(URL_API + "/login").then(response => {
    //   if (response.data.loggedIn) {
    //     setUser(response.data.user);
    //   } else {
    //     setUser(null);
    //   }
    //   console.log(response.data.user);
    // });
    token &&
      setUser({
        username: "jorge",
        name: "Jorge",
        email: "jorge.andrade@datacivica.org",
      });
  }, []);

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => {
  return useContext(AuthContext);
};
