// @ts-nocheck

import { useState } from "react";
import { useAuth } from "../hooks/AuthProvider";

const Login = () => {
  const [input, setInput] = useState({
    username: "",
    email: "",
    password: "",
  });

  const auth = useAuth();

  const handleSubmitEvent = e => {
    e.preventDefault();
    //if (input.username !== "" && input.password !== "") {
    auth.loginAction(input);
    return;
    //}
    //alert("pleae provide a valid input");
  };

  const handleInput = e => {
    const { name, value } = e.target;
    setInput(prev => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <main className="w-full min-h-screen flex flex-col bg-[#322036]">
      <div className="w-full flex flex-col justify-center items-center grow shrink-0 basis-auto">
        <div
          data-aos="zoom-in"
          data-aos-easing="ease"
          data-aos-duration="400"
          className="w-10/12 md:w-6/12 lg:w-5/12 xl:w-3/12 p-10 pb-14 flex flex-col justify-center items-center border border-[#946EA5] rounded-sm bg-[#D4C5DB]"
        >
          <form
            className="w-full flex flex-col justify-center items-center gap-5"
            onSubmit={handleSubmitEvent}
          >
            <div className="form-control w-full max-w-md">
              <label className="label user-email">
                <span className="label-text text-[#9B7ED6] font-roboto font-semibold leading-5 tracking-normal">
                  Correo electrónico:
                </span>
              </label>
              <input
                className="input input-bordered w-full"
                type="email"
                id="user-email"
                name="email"
                placeholder="ejemplo@gmail.com"
                aria-describedby="user-email"
                aria-invalid="false"
                onChange={handleInput}
              />

              <div id="user-email" className="sr-only">
                Please enter a valid username. It must contain at least 6
                characters.
              </div>
            </div>
            <div className="form-control w-full max-w-md">
              <label className="label password" htmlFor="password">
                <span className="label-text text-[#9B7ED6] font-roboto font-semibold leading-5 tracking-normal">
                  Contraseña:
                </span>
              </label>
              <input
                className="input input-bordered w-full"
                type="password"
                id="password"
                name="password"
                aria-describedby="user-password"
                aria-invalid="false"
                onChange={handleInput}
              />
              <div id="user-password" className="sr-only">
                your password should be more than 6 character
              </div>
            </div>
            <button className="w-full mt-10 btn h-10 min-h-0 bg-[#946EA5] border-[#946EA5] text-white hover:text-[#946EA5] hover:border-[#D9D9D9] hover:bg-[#D9D9D9] font-roboto font-semibold">
              Iniciar sesión
            </button>
          </form>
        </div>
      </div>
    </main>
  );
};

export default Login;
