// @ts-nocheck

import { useEffect } from "react";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AuthProvider from "./hooks/AuthProvider";
import PrivateRoute from "./hooks/PrivateRoute";

import Header from "./components/Header";
import Footer from "./components/Footer";

import Login from "./views/Login";
import Home from "./views/Home";
import Cropper from "./views/Cropper";

// Supports weights 100-900
import "@fontsource-variable/inter";
import "@fontsource-variable/bitter";
import "@fontsource/roboto";
// Supports weights 100-700
import "@fontsource-variable/roboto-mono";

import "animate.css";

import AOS from "aos";
import "aos/dist/aos.css";

//import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";

import Modal from "react-modal";
Modal.setAppElement("#root");

const App = () => {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <main>
      {/* <ScrollToHashElement behavior="smooth" inline="center" block="center" /> */}
      <Router>
        <AuthProvider>
          <Header />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Home />} />
              <Route path="/recortes" element={<Cropper />} />
            </Route>
            {/* Other routes */}
          </Routes>
          <Footer />
        </AuthProvider>
      </Router>
    </main>
  );
};

export default App;
