import { HuellaType } from "@/types";

export const huellas: HuellaType[] = [
  {
    huella: "Pulgar Derecho",
  },
  {
    huella: "Índice Derecho",
  },
  {
    huella: "Medio Derecho",
  },
  {
    huella: "Anular Derecho",
  },
  {
    huella: "Meñique Derecho",
  },
  {
    huella: "Pulgar Izquierdo",
  },
  {
    huella: "Índice Izquierdo",
  },
  {
    huella: "Medio Izquierdo",
  },
  {
    huella: "Anular Izquierdo",
  },
  {
    huella: "Meñique Izquierdo",
  },
];
