// @ts-nocheck

import { FC, useRef, useState } from "react";
import {
  CropperRef,
  FixedCropperRef,
  ImageRestriction,
} from "react-advanced-cropper";
import { FixedCropper } from "react-advanced-cropper";

import "react-advanced-cropper/dist/style.css";
import "react-advanced-cropper/dist/themes/compact.css";

// Types
import { ImageCropperProps } from "@/types/props";
import { RiRestartLine } from "react-icons/ri";

import { GoZoomIn, GoZoomOut } from "react-icons/go";
import {
  FaArrowRotateLeft,
  FaArrowRotateRight,
  FaLock,
  FaLockOpen,
} from "react-icons/fa6";

const ImageCropperAdvanced: FC<ImageCropperProps> = ({
  imageToCrop,
  onImageCropped = () => {},
  crop,
  setCrop,
}) => {
  const cropperRef = useRef<FixedCropperRef>(null);
  const [lockZoom, setLockZoom] = useState(false);
  const [lockRotate, setLockRotate] = useState(false);
  const onChange = (cropper: CropperRef) => {
    if (cropper.getCanvas() === null) {
      return;
    }
    const im = cropper.getCanvas()?.toDataURL();
    if (im === undefined) {
      return;
    }
    const canvas = cropper.getCanvas({
      height: 416,
      width: 416,
      maxHeight: 416,
      maxWidth: 416,
      minHeight: 416,
      minWidth: 416,
    });
    // const blob= b64toBlob(im, "image/png");
    // const blobUrl = URL.createObjectURL(blob);
    new Promise<string>((resolve, reject) => {
      canvas?.toBlob(
        blob => {
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }

          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);
          onImageCropped(croppedImageUrl);
          resolve(croppedImageUrl);
        },
        "image/png",
        1.0
      );
    });
  };

  const rotate = (angle: number) => {
    if (cropperRef.current) {
      cropperRef.current.rotateImage(angle);
    }
  };

  const zoom = (value: number) => {
    if (cropperRef.current) {
      cropperRef.current.zoomImage(value); // zoom-in 2x
    }
  };

  return (
    <div className="w-full flex flex-col justify-center items-center gap-10">
      <div className="flex gap-10 w-full justify-center text-center mt-5">
        <div className="w-full justify-center flex flex-row items-center max-w-5xl">
          <button
            type="button"
            aria-label="Alejar imagen"
            className="flex justify-center items-center w-14 h-10 text-white font-bold text-3xl mr-4 antialiased rounded-full bg-light-blurple sm:w-11"
            onClick={() => {
              lockZoom === false && zoom(0.9);
            }}
          >
            <GoZoomOut size={20} />
          </button>
          <button
            type="button"
            aria-label="Alejar imagen"
            className="flex justify-center items-center w-14 h-10 text-white font-bold text-3xl mr-4 antialiased rounded-full bg-light-blurple sm:w-11"
            onClick={() => {
              lockZoom === false && zoom(0.1);
            }}
          >
            <RiRestartLine size={20} />
          </button>
          <button
            type="button"
            aria-label="Alejar imagen"
            className={`flex justify-center items-center w-14 h-10 text-white font-bold text-3xl antialiased rounded-full bg-light-blurple sm:w-11 ${
              lockZoom === true ? "bg-red-500" : "bg-light-blurple"
            }`}
            onClick={() => {
              setLockZoom(!lockZoom);
            }}
          >
            {lockZoom === true ? (
              <FaLock size={20} />
            ) : (
              <FaLockOpen size={20} />
            )}
          </button>
          <button
            type="button"
            aria-label="Ampliar imagen"
            className="flex justify-center items-center w-14 h-11 text-white font-bold text-3xl ml-4 antialiased rounded-[50%] bg-light-blurple sm:w-11"
            onClick={() => {
              lockZoom === false && zoom(1.1);
            }}
          >
            <GoZoomIn size={20} />
          </button>
        </div>
        <div className="w-full justify-center flex flex-row items-center max-w-5xl">
          <button
            type="button"
            aria-label="Alejar imagen"
            className="flex justify-center items-center w-14 h-10 text-white font-bold text-3xl mr-4 antialiased rounded-full bg-light-blurple sm:w-11"
            onClick={() => {
              lockRotate === false && rotate(-10);
            }}
          >
            <FaArrowRotateLeft size={20} />
          </button>
          <button
            type="button"
            aria-label="Alejar imagen"
            className={`flex justify-center items-center w-14 h-10 text-white font-bold text-3xl  antialiased rounded-full  sm:w-11 ${
              lockRotate === true ? "bg-red-500" : "bg-light-blurple"
            }`}
            onClick={() => {
              setLockRotate(!lockRotate);
            }}
          >
            {lockRotate === true ? (
              <FaLock size={20} />
            ) : (
              <FaLockOpen size={20} />
            )}
          </button>
          <button
            type="button"
            aria-label="Ampliar imagen"
            className="flex justify-center items-center w-14 h-11 text-white font-bold text-3xl ml-4 antialiased rounded-[50%] bg-light-blurple sm:w-11"
            onClick={() => {
              lockRotate === false && rotate(10);
            }}
          >
            <FaArrowRotateRight size={20} />
          </button>
        </div>
      </div>
      <div className="relative w-full h-[400px] md:h-[600px]">
        <FixedCropper
          ref={cropperRef}
          src={imageToCrop}
          onTransitionsEnd={onChange}
          onInteractionEnd={onChange}
          backgroundWrapperProps={{
            scaleImage: lockZoom === true ? false : true,
          }}
          stencilProps={{
            handlers: false,
            lines: false,
            movable: false,
            resizable: false,
            grid: true,
            // aspectRatio: 1,
          }}
          stencilSize={{
            width: 416,
            height: 416,
          }}
          imageRestriction={ImageRestriction.stencil}
          className={"croppers-wizard m-auto h-[800px] w-[1000px]"}
        />
      </div>
    </div>
  );
};
export default ImageCropperAdvanced;
