// @ts-nocheck

import { useState } from "react";

import { Link } from "react-router-dom";
import Modal from "react-modal";
import { useLocation } from "react-router-dom";
import clsx from "clsx";

import { useAuth } from "../hooks/AuthProvider";

import { MdWarning } from "react-icons/md";

const Header = () => {
  const auth = useAuth();
  const location = useLocation();
  const [modalIsOpen, setIsOpen] = useState(false);

  return (
    <div className="fixed inset-x-0 top-0 w-full bg-[#322036] flex shrink-0 flex-row justify-center items-center py-3 border-b border-[#9B7ED6] z-20">
      {/* <div className="row flex flex-column justify-center items-center">
        <HeaderLogo>
          <img
            src={logo}
            alt="Logo de la Fiscalía General de Justicia del Estado de Tamaulipas"
          />
        </HeaderLogo>
      </div> */}

      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        //style={customStyles}
        contentLabel="Cerrar sesión"
        shouldCloseOnOverlayClick={false}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#D9D9D9] w-8/12 md:w-6/12 lg:w-5/12 xl:w-3/12 h-1/4 flex flex-col justify-center items-center gap-4 p-4 rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <MdWarning className="text-[#c86a2a] text-4xl" />
        <h2 className="text-center text-lg md:text-xl lg:text-xl xl:text-xl font-roboto font-semibold leading-8 tracking-wide text-[#493352]">
          ¿Deseas cerrar sesión?
        </h2>
        <div className="mt-4 w-full flex justify-center gap-5">
          <button
            className="w-5/12 btn h-10 min-h-0 bg-[#9B7ED6] border-[#9B7ED6] text-white hover:text-[#9B7ED6] hover:border-[#D4C5DB] hover:bg-[#D4C5DB] font-roboto font-semibold"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
          <button
            className="w-5/12 btn h-10 min-h-0 bg-[#946EA5] border-[#946EA5] text-white hover:text-[#946EA5] hover:border-[#D4C5DB] hover:bg-[#D4C5DB] font-roboto font-semibold"
            onClick={() => {
              setIsOpen(false);
              auth.logOut();
            }}
          >
            Cerrar sesión
          </button>
        </div>
      </Modal>

      <div className="w-full flex flex-row px-3 md:px-6">
        {/* <HeaderSubtitle>Sistema de Servicios Periciales</HeaderSubtitle> */}
        <Link
          to="/"
          className="w-5/12 lg:w-4/12 flex flex-row items-center gap-1"
        >
          <img alt="" src="/assets/images/Logo.png" className="w-auto h-6" />
          <div className="text-sm md:text-base text-left font-roboto-mono font-medium leading-5 md:leading-5 tracking-normal text-white uppercase">
            Convertidor de huellas dactilares
          </div>
        </Link>
        <div
          className={clsx(
            "w-7/12 lg:w-8/12 flex-row justify-end items-center",
            location.pathname === "/login" ? "hidden" : "flex"
          )}
        >
          <div className="w-5/12 md:w-6/12 lg:w-4/12 xl:w-3/12 flex flex-row justify-center items-center gap-4 mr-5">
            <Link
              to="/"
              className="text-center text-xs md:text-sm lg:text-base text-white hover:text-[#946EA5] font-roboto font-normal"
            >
              Inicio
            </Link>
            <Link
              to="/#faq"
              className="text-center text-xs md:text-sm lg:text-base text-white hover:text-[#946EA5] font-roboto font-normal"
            >
              Preguntas frecuentes
            </Link>
          </div>
          <button
            className="btn h-8 min-h-0 bg-[#946EA5] border-[#946EA5] text-white hover:text-[#946EA5] hover:border-[#D9D9D9] hover:bg-[#D9D9D9] font-roboto font-semibold"
            onClick={() => setIsOpen(true)}
          >
            Cerrar sesión
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
