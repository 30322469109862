// @ts-nocheck

import {
  FooterContainer,
  FooterLogo,
  FooterTitle,
  FooterCopyright,
} from "../styles/styles";

import logo from "../assets/images/fgjtam.png";

const Footer = () => {
  return (
    <div className="fixed inset-x-0 bottom-0 w-full shrink-0 footer p-5 bg-[#322036] border-t border-[#9B7ED6] z-20">
      <div className="w-full flex items-center justify-center">
        {/* <FooterLogo>
          <img
            src={logo}
            alt="Logo de la Fiscalía General de Justicia del Estado de Tamaulipas"
          />
        </FooterLogo> */}
        {/* <FooterTitle>Convertidor de huellas dactilares</FooterTitle> */}
        {/* <FooterCopyright>
          Aplicación implementada por{" "}
          <span className="font-semibold">Data Cívica</span> con el apoyo de la{" "}
          <span className="font-semibold">
            Fiscalía Especializada en la Investigación de los Delitos de
            Desaparición Forzada de Personas de la Fiscalía General de Justicia
            del Estado de Tamaulipas
          </span>
          , <span className="font-semibold">Comisión Nacional de Búsqueda</span>{" "}
          y{" "}
          <span className="font-semibold">
            Fondo de Población de las Naciones Unidas
          </span>
        </FooterCopyright> */}
        <p className="text-center text-xs font-roboto-mono font-normal leading-4 tracking-normal text-white">
          Leyenda de deslinde de responsabilidades. Lorem ipsum dolor sit amet
          consectetur. Eget pharetra risus in aliquet donec sollicitudin.
          Aliquam ac duis gravida natoque odio facilisi. Bibendum arcu sed in
          rutrum suspendisse nam.
        </p>
      </div>
    </div>
  );
};

export default Footer;
