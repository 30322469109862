// @ts-nocheck

import { ChangeEventHandler, ChangeEvent, useState } from "react";
import axios from "axios";
import { Point } from "react-easy-crop/types";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import clsx from "clsx";

// Componentes
import ImageCropperAdvanced from "../components/ImageCropperAdvanced";
import FilePicker from "../components/FilePicker";
import Header from "../components/Header";
import Footer from "../components/Footer";

import { AiOutlineDelete } from "react-icons/ai";
import { MdWarning } from "react-icons/md";

// Styles
import {
  UploadTitle,
  UploadSubitle,
  PrintText,
  CTAButton,
  UploadAndCrop,
} from "../styles/styles";

// Const
import { huellas as HUELLAS } from "../constants";

const Cropper = () => {
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [isUploading, setIsUploading] = useState(false);
  const [imageURLs, setImageURLs] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [imageList, setImageList] = useState(HUELLAS);
  const [error, setError] = useState<Error>();
  const [isFileUploading, setIsFileUploading] = useState(false);
  const [validMessage, setValidMessage] = useState("");
  const [nuc, setNuc] = useState<string>("");
  const [rotation, setRotation] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);

  const addCroppedImage = (croppedImage: string) => {
    const newImages = [...imageList];

    newImages[selectedImageIndex] = {
      ...newImages[selectedImageIndex],
      url: croppedImage,
    };
    setImageList(newImages);
  };

  const handleSelectChange = (e: ChangeEvent<HTMLSelectElement>) => {
    setSelectedImageIndex(parseInt(e.target.value, 10));
  };

  const uploadCroppedImages = async () => {
    setIsUploading(true);
    setError(undefined);

    try {
      const formData = new FormData();
      const imageListExport = imageList.filter(img => img.url);

      // Map images to array of axios.post Promises
      if (imageListExport.length > 0) {
        // Add NUC to the body
        formData.append("nuc", nuc);

        for (let i = 0; i < imageListExport.length; i++) {
          const fileUrl = imageListExport[i].url || "";
          const huella = imageListExport[i].huella;
          const response = await fetch(fileUrl);
          const blob = await response.blob();

          // console.log(blob.type);

          formData.append("file", blob, `${huella}.${blob.type.split("/")[1]}`);
        }

        const response = await axios.post(
          // "https://huellasdactilares.datacivica.org/api/v1/fingerprint",
          "https://convertidordehuellas-stage.datacivica.org/api/v1/fingerprint",
          // "http://localhost:5000/api/v1/fingerprint",
          // "http://localhost:5001/api/v1/fingerprint",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; application/octet-stream",
            },
            responseType: "blob",
          }
        );

        // console.log(response);

        // console.log(response.headers["content-disposition"]);

        const filename = response.headers["content-disposition"]
          .split(";")[1]
          .trim()
          .split("=")[1];

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const button = document.createElement("button");
        button.classList.add("my-4");
        const link = document.createElement("a");
        link.innerHTML = "Descargar últimas imágenes guardadas";
        link.href = url;
        link.classList.add("download");
        link.setAttribute("download", filename); //or any other extension
        button.appendChild(link);

        if (link) {
          link.click();
        }

        button.remove();
      }
    } catch (error) {
      console.error(error);
      setError(error as Error);
    } finally {
      setIsUploading(false);
    }
  };

  const uploadPdf = async (pdfFile: File | null | undefined) => {
    setIsFileUploading(true);

    try {
      const formData = new FormData();
      // Map images to array of axios.post Promises

      if (pdfFile) {
        const fileURLs: string[] = [];
        fileURLs.push(URL.createObjectURL(pdfFile));

        for (let i = 0; i < fileURLs.length; i++) {
          const response = await fetch(`${fileURLs}`);
          const blob = await response.blob();

          formData.append(
            "file",
            blob,
            `${pdfFile.name}.${blob.type.split("/")[1]}`
          );
        }

        const response = await axios.post(
          // "http://localhost:5000/api/v1/pdf/conversion",
          // "http://localhost:5001/api/v1/pdf/conversion",
          // "https://huellasdactilares.datacivica.org/api/v1/pdf/conversion",
          "https://convertidordehuellas-stage.datacivica.org/api/v1/pdf/conversion",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data; application/octet-stream",
            },
            responseType: "blob",
          }
        );

        const url = window.URL.createObjectURL(new Blob([response.data]));
        setImageURLs([`${url}`]);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error) {
      console.error(error);
      setError(error as Error);
    } finally {
      setIsFileUploading(false);
    }
  };

  const onFileChange: ChangeEventHandler<HTMLInputElement> = event => {
    setValidMessage(" ");
    const filePdf = event.target.files?.item(0);
    const fileType = filePdf?.type;
    const validPdfTypes = ["application/pdf"];

    if (validPdfTypes.includes(`${fileType}`)) {
      uploadPdf(filePdf);
    } else {
      setValidMessage("Sólo se aceptan PDFs");
    }
  };

  return (
    <main
      className="Cropper pt-28 pb-24 w-full min-h-screen flex flex-col bg-[#322036]"
      data-theme="light"
    >
      <Modal
        isOpen={modalIsOpen}
        //onAfterOpen={afterOpenModal}
        onRequestClose={() => setIsOpen(false)}
        //style={customStyles}
        contentLabel="Cerrar sesión"
        shouldCloseOnOverlayClick={false}
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-[#D9D9D9] w-8/12 md:w-6/12 lg:w-5/12 xl:w-3/12 h-1/4 flex flex-col justify-center items-center gap-4 p-4 rounded-lg shadow-lg"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <MdWarning className="text-[#c86a2a] text-4xl" />
        <h2 className="text-center text-lg md:text-xl lg:text-xl xl:text-xl font-roboto font-semibold leading-8 tracking-wide text-[#493352]">
          ¿Deseas eliminar el proceso y comenzar uno nuevo?
        </h2>
        <div className="mt-4 w-full flex justify-center gap-5">
          <button
            className="w-5/12 btn h-10 min-h-0 bg-[#9B7ED6] border-[#9B7ED6] text-white hover:text-[#9B7ED6] hover:border-[#D4C5DB] hover:bg-[#D4C5DB] font-roboto font-semibold"
            onClick={() => setIsOpen(false)}
          >
            Cancelar
          </button>
          <Link
            reloadDocument
            to="/recortes"
            className="w-5/12 btn h-10 min-h-0 bg-[#946EA5] border-[#946EA5] text-white hover:text-[#946EA5] hover:border-[#D4C5DB] hover:bg-[#D4C5DB] font-roboto font-semibold"
            onClick={() => setIsOpen(false)}
          >
            Comenzar nuevo
          </Link>
        </div>
      </Modal>
      <UploadAndCrop>
        {imageURLs.length === 0 ? (
          <div className="flex flex-col justify-center">
            <UploadTitle>
              ¿De qué documento se harán los recortes de huellas?
            </UploadTitle>
            <UploadSubitle>
              Se utilizará una documento PDF como fuente y se harán recortes de
              las áreas necesarias sobre éste.
            </UploadSubitle>
            <div className="flex flex-col items-center justify-center">
              <div className="flex flex-col gap-3 justify-center w-full items-center">
                <div className="form-control w-full max-w-md">
                  <label className="label">
                    <span className="label-text text-white font-semibold">
                      Número de Control de Proceso (NCP):
                    </span>
                  </label>
                  <input
                    type="text"
                    value={nuc}
                    onChange={e => setNuc(e.target.value)}
                    placeholder="Escriba el número de control (NCP)"
                    className="input input-bordered w-full"
                  />
                </div>
                <FilePicker
                  disabled={nuc === ""}
                  onFileChange={onFileChange}
                  loading={isFileUploading}
                  label={"Seleccione un archivo:"}
                />
                {!validMessage && (
                  <p className="text-center flex justify-center items-center text-red-500">
                    {validMessage}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div>
            {!isFileUploading && (
              <>
                <UploadSubitle>
                  Carga de imagen fuente {">"} Recorte de huellas dactilares
                </UploadSubitle>
                <p className="text-white font-bold text-center text-xl pb-4 md:text-3xl">
                  Recorta las 10 huellas
                </p>
                <div className="flex flex-col gap-4 items-center justify-center mb-6">
                  <p className="text-white md:text-xl">
                    Número de control (NCP):{" "}
                    <span className="font-bold">{nuc}</span>
                  </p>
                  <div className="form-control w-full max-w-md pb-5">
                    <label className="label">
                      <span className="label-text text-white font-bold">
                        Selecciona la huella a recortar:
                      </span>
                    </label>
                    <select
                      onChange={handleSelectChange}
                      value={selectedImageIndex}
                      className="select select-bordered text-dark-blue"
                    >
                      {imageList.map((img, i) => (
                        <option key={img.huella} value={i}>
                          {img.huella}
                        </option>
                      ))}
                    </select>
                  </div>
                  <p className="text-white text-lg">
                    Arrastra la imagen para ubicar el recorte en la posición que
                    deseas y desliza la barra para ampliar o alejar la imagen:
                  </p>
                </div>
                {imageURLs.map((imageSrc, index) => (
                  <div
                    className="flex flex-col justify-center gap-6"
                    key={imageSrc}
                  >
                    <ImageCropperAdvanced
                      imageToCrop={imageSrc}
                      key={index}
                      crop={crop}
                      setCrop={setCrop}
                      onImageCropped={croppedImage => {
                        if (croppedImage) {
                          addCroppedImage(croppedImage);
                        }
                      }}
                    />
                  </div>
                ))}
                <p className="flex items-center justify-center py-5 text-white text-md md:text-xl">
                  Estás recortando:
                  <span className="pl-1 font-bold">
                    {imageList[selectedImageIndex].huella}
                  </span>
                </p>
                <div className="grid grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                  {imageList

                    .filter(i => i.url)
                    .map((huella, index) => (
                      <div
                        className="min-w-[100px] flex flex-col w-full h-full"
                        key={index}
                      >
                        <PrintText>{huella.huella}</PrintText>
                        <button
                          type="button"
                          className="ml-44"
                          data-index={huella.huella}
                          onClick={e => {
                            let eventTarget = e.target as HTMLElement;
                            eventTarget = (
                              eventTarget.localName === "svg"
                                ? eventTarget.parentElement
                                : eventTarget.localName === "path"
                                ? eventTarget.parentElement?.parentElement
                                : eventTarget
                            ) as HTMLElement;
                            let indexAttr =
                              eventTarget.getAttribute("data-index");
                            // let indexSearch = parseInt(indexAttr??"",10);
                            let newImages = imageList.map((item, index) => {
                              if (indexAttr === item.huella) {
                                return { huella: item.huella };
                              } else {
                                return item;
                              }
                            });
                            setImageList(newImages);
                          }}
                        >
                          {" "}
                          <AiOutlineDelete size={20} style={{ color: "red" }} />
                        </button>
                        <img
                          className="min-w-full w-full md:min-h-[150px] max-h-[224px] p-3"
                          alt={`Recorte del dedo ${huella.huella}`}
                          src={huella.url}
                        />
                      </div>
                    ))}
                </div>
                <div
                  id="buttons"
                  className="flex flex-col justify-center w-full items-center mt-8"
                >
                  {/* <p className="text-white mt-10 mb-3 text-md md:text-xl">
                    Una vez que termines de recortar las imágenes, puedes
                    guardarlas:
                  </p> */}
                  <button
                    onClick={uploadCroppedImages}
                    disabled={!imageList.find(img => img.url) || isUploading}
                    //className={isUploading ? "loading" : undefined}
                    className={clsx(
                      "w-5/12 mt-20 btn h-12 min-h-0 bg-[#946EA5] border-[#946EA5] text-white text-md md:text-xl hover:text-[#946EA5] hover:border-[#D4C5DB] hover:bg-[#D4C5DB] font-roboto font-semibold disabled:border-gray-300 disabled:text-gray-300 disabled:cursor-not-allowed disabled:bg-darkish-primary-blue disabled:hover:border-gray-300 disabled:hover:text-gray-300 disabled:hover:bg-darkish-primary-blue",
                      isUploading ? "disabled" : undefined
                    )}
                  >
                    <span
                      className={clsx(isUploading ? "loading" : undefined)}
                    ></span>
                    Guardar imágenes
                  </button>
                  {error && (
                    <p className="text-center flex justify-center items-center text-red-500">
                      Hubo un error al procesar tu solicitud. Error: "
                      {error.message}"
                    </p>
                  )}
                  <div className="h-6"></div>
                </div>
              </>
            )}
            <br></br>
            <br></br>
            <hr
              className=" w-[50%px] >

           </div>"
            ></hr>

            <div className="md:flex sm:flex-wrap justify-center w-full items-center text-center mt-8">
              {/* <p className="text-white mb-3 text-md md:text-xl">
                <br></br>
                <br></br>
                 {isFileUploading
                  ? "Cargando el nuevo archivo..."
                  : "Tras guardar las imágenes, puedes cargar aquí un nuevo .PDF:"}
                También puedes realizar una nueva conversión por completo:
              </p> */}
              <div className="flex flex-col justify-center w-full items-center">
                {/* <FilePicker
                  onFileChange={onFileChange}
                  loading={isFileUploading}
                  label={"Seleccione un nuevo archivo:"}
                />
                {!validMessage && (
                  <p className="text-center flex justify-center items-center text-red-500">
                    {validMessage}
                  </p>
                )} */}
                <button
                  className="mt-10 text-center text-lg md:text-xl lg:text-xl xl:text-xl font-roboto font-normal leading-8 tracking-wide text-white underline underline-offset-2"
                  onClick={() => setIsOpen(true)}
                >
                  Finalizar proceso y comenzar uno nuevo
                </button>
              </div>
            </div>
          </div>
        )}
      </UploadAndCrop>
    </main>
  );
};

export default Cropper;
